
import { Vue, Component } from 'vue-property-decorator'
import Base from './Base.vue'

@Component({
  components: {
    Base
  }
})
export default class Add extends Vue {
}
