
import { Vue, Component } from 'vue-property-decorator'
import AMapLoader from '@amap/amap-jsapi-loader'
import { mapKey } from '@/utils/config'
import { ProjectAdd } from '@/types/project'
import { ElForm } from 'element-ui/types/form'
import { twoDecimal } from '@/utils/validate'
import { FetchSuggestionsCallback } from 'element-ui/types/autocomplete'

@Component
export default class Base extends Vue {
  private addrList: object[] = []
  private addr: [string, string, string] = ['', '', '']
  private info: ProjectAdd = {
    projectName: '',
    projectProvince: '',
    projectCity: '',
    projectArea: '',
    projectAddr: '',
    longitude: null,
    latitude: null,
    maintStartTime: null,
    maintEndTime: null,
    maintAcreage: '',
    maintUnit: '',
    greenAcreage: '',
    watersAcreage: '',
    projectType: '', // 项目类型
    projectVillage: '' // 街道
  }

  villageList = []

  private projectTypeList: Array<{dicValue: string; dicCode: string}>= []

  private startPickerOptions = {
    disabledDate: (time: Date) => {
      if (this.info.maintEndTime && this.info.maintEndTime) {
        return time.getTime() > Number(this.info.maintEndTime)
      } else {
        return false
      }
    }
  }

  private endPickerOptions = {
    disabledDate: (time: Date) => {
      if (this.info.maintStartTime && this.info.maintStartTime) {
        return time.getTime() < Number(this.info.maintStartTime)
      } else {
        return false
      }
    }
  }

  private rules = {
    projectName: [
      { required: true, message: '请输入项目名称', trigger: ['blur', 'change'], whitespace: true }
    ],
    projectArea: [
      { required: true, message: '请选择省市区', trigger: 'change' }
    ],
    projectAddr: [
      { required: true, message: '请输入项目详细地址', trigger: ['blur', 'change'] }
    ],
    maintStartTime: [
      { required: true, message: '请选择开始时间', trigger: 'change' }
    ],
    maintEndTime: [
      { required: true, message: '请选择结束时间', trigger: 'change' }
    ],
    maintAcreage: [
      { validator: twoDecimal }
    ],
    greenAcreage: [
      { validator: twoDecimal }
    ],
    watersAcreage: [
      { validator: twoDecimal }
    ]
  }

  private isAdding = false
  private isEdit = true
  $refs!: {
    info: ElForm;
  }

  get projectId () {
    return this.$route.query.projectId || ''
  }

  created (): void {
    // 新增
    this.getAddrList()
    !this.projectId && this.getProjectTypeList()
    if (this.projectId !== '') {
      this.isEdit = false
      this.getProjectDetail()
    }
  }

  // 获取项目信息
  getProjectDetail () {
    this.$axios.get(this.$apis.project.selectProjectById, { projectId: this.projectId }).then(res => {
      this.info = res || []
    })
  }

  // 获取地址信息
  getAddrList () {
    this.$axios.get(this.$apis.common.getRegion).then(res => {
      this.addrList = res || []
    })
  }

  // 街道信息
  getVillageList (city: string, area: string) {
    this.$axios.get(this.$apis.project.selectVillageList, {
      projectCity: city,
      projectArea: area
    }).then(res => {
      this.villageList = res || []
    })
  }

  // 获取项目类型
  getProjectTypeList (callback?: any) {
    this.$axios.get(this.$apis.common.selectDicByList, { dicType: 'projectType' }).then(res => {
      this.projectTypeList = res.projectType || []
      callback && callback()
    })
  }

  areaChange () {
    if (this.addr.length === 3 && this.addr[2] !== '') {
      this.info.projectProvince = this.addr[0] || ''
      this.info.projectCity = this.addr[1] || ''
      this.info.projectArea = this.addr[2] || ''
      this.getVillageList(this.info.projectCity, this.info.projectArea)
    } else {
      this.info.projectProvince = ''
      this.info.projectCity = ''
      this.info.projectArea = ''
      this.villageList = []
    }
    this.info.projectVillage = ''
  }

  // 详细地址输入
  addrFetch (queryString: string, cb: FetchSuggestionsCallback) {
    AMapLoader.load({
      key: mapKey,
      version: '2.0',
      plugins: [
        'AMap.AutoComplete',
        'AMap.Geocoder'
      ]
    }).then(() => {
      // 根据所选区域初始化项目经纬度
      const geocoder = new AMap.Geocoder({
        city: this.info.projectCity
      })
      geocoder.getLocation(this.info.projectArea, (status, result) => {
        const res = result as AMap.Geocoder.GeocodeResult
        if (status === 'complete' && res.geocodes.length) {
          this.info.latitude = res.geocodes[0].location.getLat().toString()
          this.info.longitude = res.geocodes[0].location.getLng().toString()
        }
      })
      // 实例化Autocomplete
      const autoComplete = new AMap.Autocomplete({
        city: this.info.projectCity
      })
      autoComplete.search(this.info.projectArea + queryString, (status, result) => {
        const res = result as AMap.Autocomplete.SearchResult
        if (status === 'complete') {
          cb(res.tips)
        }
      })
    })
  }

  // 点击详细地址下拉
  addrChange (data: AMap.Autocomplete.Tip) {
    this.info.projectAddr = data.name
    this.info.latitude = data.location.getLat().toString()
    this.info.longitude = data.location.getLng().toString()
  }

  handleAdd () {
    this.$refs.info.validate(valid => {
      if (valid) {
        if (this.projectId !== '') {
          this.editProject()
        } else {
          this.addProject()
        }
      }
    })
  }

  addProject () {
    this.isAdding = true
    this.$axios.post(this.$apis.project.insertProject, this.info).then(() => {
      this.$message.success('添加成功')
      this.$router.push('/projectList')
      this.$store.dispatch('getProjectList')
    }).finally(() => {
      this.isAdding = false
    })
  }

  // 编辑项目信息
  showEdit () {
    // 查询项目类型字典
    this.getProjectTypeList(() => {
      this.isEdit = true
      this.info.maintStartTime = this.info.maintStartTime ? new Date(this.info.maintStartTime).getTime().toString() : null
      this.info.maintEndTime = this.info.maintEndTime ? new Date(this.info.maintEndTime).getTime().toString() : null
      this.addr = [this.info.projectProvince, this.info.projectCity, this.info.projectArea]
      this.getVillageList(this.info.projectCity, this.info.projectArea)
    })
  }

  editProject () {
    this.isAdding = true
    this.$axios.post(this.$apis.project.updateProject, this.info).then(() => {
      this.$message.success('修改成功')
      this.isEdit = false
      this.getProjectDetail()
      this.$store.dispatch('getProjectList')
    }).finally(() => {
      this.isAdding = false
    })
  }
}
